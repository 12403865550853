import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import styles from '../../../css/baseStyle.css';

import { sizing } from '@material-ui/system';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    MenuItem,
    TableCell,
    Button,
    FormControl,
    Select,
    Paper,
    Grid,
    TablePagination,
    Modal,
    IconButton,
} from '@material-ui/core';

import TablePaginationActions from '../components/TablePaginationActions';
import AddPackSize from '../components/AddPackSize';
import OrderDetails from '../components/OrderDetails';
import Skeleton from '../../common/components/Skeleton';
// Constants
var BrandArrayID = 0;
var ProductArrayID = 0;

// Styles
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexWrap: 'wrap',
        padding: '1% 8% 4% 8%',

        '& > *': {
            //   margin: theme.spacing(1),
            //   width: theme.spacing(16),
            //   height: theme.spacing(16),

            width: '100%',
            height: 'auto',
        },
    },
    labelTextContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    labelText: {
        textAlign: 'left',
        font: 'normal normal normal 16px/21px Roboto',
        color: '#333232',
        opacity: '1',
    },
    containerPaper: {
        display: 'flex',
        flexGrow: 1,
        flexWrap: 'wrap',
        padding: '3%',
        marginBottom: '4%',
    },
    depotSubTitle: {
        margin: '0% 0% 2% 0%',
        color: '#333232',
        float: 'left',
    },
    table: {
        minWidth: 700,
    },
    depotButtonTypo: {
        float: 'right',
    },
    textFieldMaxWidth: {
        width: '100% !important',
    },
    containerMargin: {
        marginBottom: '5px',
    },
    depotButton: {
        color: '#fff',
        background: '#D52B1E',
        textAlign: 'center',
        '&:hover': {
            background: '#D52B1E',
        },
    },
    selectPadding: {
        margin: '-3px 0px -3px 0px',
    },
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#D52B1E',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const customColumnStyle = { maxWidth: 40 };

//Main
const ProductDetails = (props) => {
    const classes = useStyles();

    //#region States
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [displayModel, setDisplayModel] = React.useState(false);
    const [brandId, setBrandId] = React.useState(-1);
    const [productId, setProductId] = React.useState(-1);
    // const [displayBrands, setDisplayBrands] = React.useState();
    const orderDetails = props?.orderDetails?.get || [];
    const setOrderDetails = props?.orderDetails?.set;
    // const [orderDetails, setOrderDetails] = React.useState([]);
    const [update, setUpdate] = React.useState(0);
    const [productDetails, setProductDetails] = React.useState();
    const displayBrands = productDetails;

    //#endregion

    //#region  Functions/Events
    const fetchFormData = (formType) => {
        return;
    };

    
    React.useEffect(() => {
        console.log('----props----------', ...props.Products.Brands)
        let _tempDetails = props.Products.Brands;
        let _updatedOrderDetails = [];
        Object.keys(_tempDetails).forEach(key =>{ 
            console.log(_tempDetails[key])
            let tempProductArr = _tempDetails[key].Products;
            console.log(tempProductArr, typeof tempProductArr)
            let _updatedProdcutDetails = [];
            tempProductArr.forEach((p) => _updatedProdcutDetails.push({ ...p, toShow: true }));
            _tempDetails[key] = _updatedOrderDetails.push({..._tempDetails[key], Products: _updatedProdcutDetails});

        })

        console.log(_updatedOrderDetails);
        setProductDetails(_updatedOrderDetails);
    }, [props.stateCheck]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleProductSelection = (event, id, brandArrayID) => {
        //setBrandId(brandArrayID);
        //setProductId(event.target.value - 1);
        // console.log(brandArrayID);
        // console.log(event.target.value - 1);
        //BrandArrayID = brandArrayID;
        //ProductArrayID = event.target.value - 1;
        setBrandId(brandArrayID);
        setProductId(event.target.value - 1);

        // console.log(brandId);
        // console.log(productId);
        setDisplayModel(true);

        //document.getElementById('brandSelect' + id);
    };
    const handleModelOpen = () => {
        setDisplayModel(true);
    };

    const handleModelClose = () => {
        setDisplayModel(false);
    };

    const handleOnSubmitClick = (brandID, ProdID, Quantity) => {
        console.log(brandID, ProdID, Quantity);
        setOrderDetails(
            orderDetails.concat([
                {
                    Id: productDetails[brandID].Products[ProdID].ID,
                    brandID: productDetails[brandID].ID,
                    Name: productDetails[brandID].Products[ProdID].Name,
                    Packsize: productDetails[brandID].Products[ProdID].packsize,
                    Quantity: Quantity,
                },
            ])
        );
        setProductDetails((previousProductDetails) => {
            let _tempDetails = previousProductDetails;
            _tempDetails[brandID].Products[ProdID].toShow = false;
            return _tempDetails;
        });

        handleModelClose();
    };

    const handelDelete = (i) => {
        const newState = orderDetails.slice();
        console.log(orderDetails);

        setProductDetails((previousProductDetails) => {
            let tempOrderDetails = previousProductDetails;
            console.log(tempOrderDetails);
            console.log(i);
            let brandIndex = tempOrderDetails.findIndex((x) => x.ID == orderDetails[i].brandID);
            console.log(brandIndex);
            let productIndex = tempOrderDetails[brandIndex].Products.findIndex((x) => x.ID == orderDetails[i].Id);
            console.log(productIndex);

            tempOrderDetails[brandIndex].Products[productIndex].toShow = true;
            return tempOrderDetails;
        });

        if (i > -1) {
            newState.splice(i, 1);
            setOrderDetails(newState);
        }
    };
    //#endregion
    // Output
    return (
        <div>
            {productDetails != null ? (
                <>
                    <h4>Available Products</h4>
                    <Paper elevation={4} className={classes.containerPaper}>
                        {/* <p id="product-description">donde esta la biblioteca</p> */}
                        <Grid container className={classes.containerMargin}>
                            <Grid container item spacing={1} xs={6}></Grid>
                            <Grid
                                container
                                item
                                spacing={1}
                                xs={6}
                                direction="row-reverse"
                                justify="flex-start"
                                className={classes.containerRightPadding}>
                                Total Products Found:{props.Products.TotalCount}
                            </Grid>
                        </Grid>
                        {/* {props.stateCheck ? (
                            <Skeleton.table />
                        ) : ( */}
                            <>
                                {console.log(productDetails)}
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    size="small"
                                                    className={`${styles.table50pcent} ${styles.table50pcentTh}`}>
                                                    Product Name
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    size="small"
                                                    className={`${styles.table50pcent} ${styles.table50pcentTh}`}>
                                                    Add Product
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(rowsPerPage > 0
                                                ? productDetails.slice(
                                                      page * rowsPerPage,
                                                      page * rowsPerPage + rowsPerPage
                                                  )
                                                : productDetails
                                            ).map((brand, i) => (
                                                <StyledTableRow key={brand.ID}>
                                                    <TableCell
                                                        align="center"
                                                        component="th"
                                                        scope="row"
                                                        className={`${styles.table50pcent} ${styles.table50pcentTd}`}>
                                                        {brand.Name}
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        className={`${styles.table50pcent} ${styles.table50pcentTd}`}>
                                                        {brand?.Products?.filter((p) => p?.Quantities?.length > 0)?.length ==
                                                        0 ? (
                                                            <p className={styles.ColorTextPrimary}>
                                                                *Quota Limit for the product has reached.
                                                            </p>
                                                        ) : (
                                                            <FormControl
                                                                margin="dense"
                                                                className={`${classes.textFieldMaxWidth} ${classes.selectPadding}`}>
                                                                <Select
                                                                    variant="outlined"
                                                                    value={0}
                                                                    name={`brandSelect${brand.ID}`}
                                                                    disabled={
                                                                        brand.Products.filter((p) => p.toShow == true)
                                                                            .length == 0
                                                                    }
                                                                    onChange={(event) =>
                                                                        handleProductSelection(
                                                                            event,
                                                                            brand.ID,
                                                                            i + page * rowsPerPage
                                                                        )
                                                                    }>
                                                                    <MenuItem key={0} value={0}>
                                                                        Select Pack Size
                                                                    </MenuItem>
                                                                    {brand?.Products?.map((product, j) => {
                                                                        if (
                                                                            product?.toShow == true &&
                                                                            product?.Quantities?.length > 0
                                                                        ) {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={product.ID}
                                                                                    value={j + 1}>
                                                                                    {product.Name}
                                                                                </MenuItem>
                                                                            );
                                                                        }
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        )}
                                                    </TableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={productDetails.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        />
                                </TableContainer>
                                <Modal open={displayModel} onClose={handleModelClose}>
                                    {brandId != -1 && productId != -1 ? (
                                        <AddPackSize
                                            Product={productDetails[brandId].Products[productId]}
                                            Quotas = {productDetails[brandId].Products[productId].Quotas}
                                            brandID={brandId}
                                            productId={productId}
                                            ProductName={productDetails[brandId].Name}
                                            quantitySubmitClick={handleOnSubmitClick}
                                            diplayType={props.diplayType ?? 'HCP'}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Modal>
                            </>
                        {/* )} */}
                    </Paper> 
                    <OrderDetails
                        TotalCount={orderDetails?.length}
                        Orders={orderDetails}
                        stateCheck={props?.stateCheck}
                        handelDelete={handelDelete}
                        error={props?.error}
                    />
                </> ) : '' }
             {/* ) : '' */}
        </div>
        )
};

// Exports
export default ProductDetails;
