/* eslint-disable no-multi-spaces */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-return */
import React, { useEffect } from "react";
// import useP from 'react-router-dom'
/// //////////React Boostrap/////////
// import { Button, Form } from 'react-bootstrap';

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { men } from "@material-ui/core/styles";
import { AddCircleOutlineIcon } from "@material-ui/icons";
import {
  Button,
  Typography,
  Paper,
  MenuItem,
  FormHelperText,
  Grid,
  Checkbox,
  Snackbar,
  TextField,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory, useParams } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";

import slice, {
  GetSessionInformation,
} from "../../../../Store/Slices/SessionSlice";
import MuiAlert from "@material-ui/lab/Alert";
// Customs
import HCPDetails from "../../components/HCPDetails";
import ProductDetails from "../../components/ProductDetails";
import OrderDetails from "../../components/OrderDetails";
import OnClosureModal from "../../../common/components/onClosureModal";
import Consents from "../components/consents";
import styles from "../../../../css/baseStyle";
import axios from "axios";
import HCPSearchDetails from "../../components/HCPSearchDetails";
import FormInputBlock from "../../../common/components/formInputBlock";
import FormSelectBlock from "../../../common/components/formSelectBlock";
import FormTemplate from "../../components/formTemplate";
import Skeleton from "../../../common/components/Skeleton";
import CustomFullLoader from "../../../../helpers/CustomFullLoader";
import { enqueueSnackbar } from "notistack";
import { addDays, isWeekend } from 'date-fns';
/// /States////

/// //Main////

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "1% 8% 4% 8%",

    "& > *": {
      //   margin: theme.spacing(1),
      //   width: theme.spacing(16),
      //   height: theme.spacing(16),

      width: "100%",
      height: "auto",
    },
  },
  depotTitle: {
    margin: "0% 0% 2% 2%",
    color: "#333232",
  },
  depotPaper: {
    // width: '100%',
    // height: 'auto',
    //margin: '4% 8% 4% 8%',

    padding: "3%",
    marginBottom: "4%",
  },
  depotSubTitle: {
    margin: "0% 0% 2% 0%",
    color: "#333232",
    float: "left",
  },
  table: {
    minWidth: 700,
  },
  depotButtonTypo: {
    float: "right",
  },
  depotButton: {
    color: "#fff",
    background: "#D52B1E",
    textAlign: "center",
    "&:hover": {
      background: "#D52B1E",
    },
  },
  headerBottomPadding: {
    marginBottom: "20px",
  },
  labelCheckBoxContainer: {
    display: "flex",
    alignItems: "center",
  },
  labelText: {
    textAlign: "left !important",
    font: "normal normal normal 16px/21px Roboto",
    color: "#333232",
    opacity: "1",
  },
  textFieldMaxWidth: {
    width: "100% !important",
  },
  paperMaxWidth: {
    width: "100% !important",
    display: "flex",
    flexGrow: 1,
    flexWrap: "wrap",
  },
}));
class Address {
  constructor() {
    this.Line1 = "";
    this.Line2 = "";
    this.Line3 = "";
    this.AreaCode = "";
  }
}

class Quotas {
  constructor() {
    this.Total = 0;
    this.Delivered = 0;
    this.inTransit = 0;
  }
}
class Brand {
  constructor() {
    this.Name = "";
    this.ID = "";
    this.Products = [];
    this.UniqueCode = "";
    this.Quotas = new Quotas;
  }
}

class HCPSearchAPIModel {
  constructor() {
    this.countryCode = "";
    this.searchFilter = "";
    this.count = 0;
  }
}

class SubmitModel {
  constructor() {
    this.affiliate_id = 0;
    this.order_data = {};

    this.hcp_address = "";
    this.hcp_id = "";
    this.hcp_name = "";
    this.product_array = {};
    this.hcp_address_object = {};
    this.email = "";
    this.Specialty = "";
    this.atlantisCheckBox = "";
    this.attachments = {};
    this.created_on = "";
    this.created_by_user_id = 0;
    this.updated_on = "";
    this.updated_by_user_id = 0;
  }
}
const spainView = (props) => {
  
  const getNextWorkingDay = (date) => {
    let nextDate = addDays(date, 1);
    while(isWeekend(nextDate)){
      nextDate = addDays(nextDate, 1);
    }
    return nextDate;
  }

  const minDate = getNextWorkingDay(addDays(new Date(), 2));

  // const [hcpAddress, setHcpAddress] = React.useState(["abc.com"]);
  const [formId, setFormId] = React.useState('New Submission')
  const [orderDetails, setOrderDetails] = React.useState([]);
  const [deliveryInstruction, setDeliveryInstruction] = React.useState([]);
  const [createdOnDate, setCreatedOnDate] = React.useState([]);
  const [consentCheckBox, setconsentCheckBox] = React.useState(false);
  const [attachments, setAttachments] = React.useState([]);
  const [showClosure, setShowClosure] = React.useState(false);
  const [closureMessage, setClosureMessage] = React.useState();
  const [enableCancelRedirect, setEnableCancelRedirect] = React.useState(false);
  const [enableRedirect, setEnableRedirect] = React.useState(true);
  const [brandState, SetBrandState] = React.useState([]);
  const [productCount, SetProductCount] = React.useState([]);
  const [brandCheck, SetbrandCheck] = React.useState(false);
  const [productCountCheck, SetproductCountCheck] = React.useState(false);
  const [affiliateData, SetAffiliateData] = React.useState();
  const [addressState, SetAddressState] = React.useState();
  const [addressStateCheck, SetAddressStateCheck] = React.useState(false);
  const [productTableData, SetProductTableData] = React.useState();
  const [openAlert, setOpenAlert] = React.useState(true);
  const [atlantisCheckBox, setAtlantisCheckBox] = React.useState("");
  //Snackbars
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [typeSnackBar, setTypeSnackBar] = React.useState("info");
  const [textSnackBar, setTextSnackBar] = React.useState("An info message");
  const [contentChange, setContentChange] = React.useState(1);
  const [hcpSessionData, SethcpSessionData] = React.useState();
  const [announcement, SetAnnouncement] = React.useState();
  const [announcmentStatus, setAnnouncementstatus] = React.useState(false);
  const [checkBoxError, setCheckBoxError] = React.useState();
  const [productError, setProductError] = React.useState();
  const [forceUpdate, setforceUpdate] = React.useState(1);
  const [selectAddressError, setSelectAddressError] = React.useState();
  const [sessionContext, setSessionContext] = React.useState();
  const [HCPAddress, setHCPAddress] = React.useState();
  const [privacyConsent, setPrivacyConsent] = React.useState();
  const [marketingConsent, setMarketingConsent] = React.useState();
  const [dataLoadStatus, setDataLoadStatus] = React.useState(false);
  const [selectedHCPAddress, SetSelectedHCPAddress] = React.useState();
  const [salesRepConfirmation, setSalesRepConfirmation] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState();
  const [sessionCurrentUser, SetSessionCurrentUser] = React.useState();
  const [consentOnly, setConsentOnly] = React.useState(false);
  const [salesRepAddressLine1, setSalesRepAddressLine1] = React.useState("");
  const [salesRepAddressLine2, setSalesRepAddressLine2] = React.useState("");
  const [salesRepAddressLine3, setSalesRepAddressLine3] = React.useState("");
  const [salesRepAddressAreaCode, setSalesRepAddressAreaCode] =
    React.useState("");
  const [hcpName, setHCPName] = React.useState("");
  const [hcpID, setHCPID] = React.useState("");
  const [hcpEmail, setHcpEmail] = React.useState("");
  const [hcpProfession, setHCPProfession] = React.useState("");
  const [hcpSpeciallity, setHCPSpeciallity] = React.useState("");
  const [hcpAddressId, setHcpAddressId] = React.useState("");
  const [hcpaddressDropDown, setHcpaddressDropDown] = React.useState([0]);
  const [salesApprover, setSalesApprover] = React.useState("");
  const [loader, setLoader] = React.useState(true);
  const [formCheck, setFormCheck] = React.useState(false);
  

  const [addressLine, setAddressLine] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [deliverydate, setdeliverydate] = React.useState(getNextWorkingDay(addDays(new Date(), 2)));
  const [npc, setnpc] = React.useState("");
  const [adn, setadn] = React.useState("");
  const [lineno, setlineno] = React.useState("");
  const [phone, setphone] = React.useState("");
  const [secondtitle, setsecondtitle] = React.useState("");

  const [signature, setSignature] = React.useState("");
  const [approvalNow, setApprovalNow] = React.useState(true);

  const isEdit = window.location.pathname.split("/")[3] === "edit" ? true : false;
  const orderID = window.location.pathname.split("/")[4];
  const isSpainCheck = window.location.pathname.split("/")[1];

  const [endDateOpen, setEndDateOpen] = React.useState(false);
  const [startDateOpen, setStartDateOpen] = React.useState(false);


  let {id} = useParams();

  const [apiStatus, setApiStatus] = React.useState({
    api1: { success: false, error: false, completed: false },
    api2: { success: false, error: false, completed: false },
    api3: { success: false, error: false, completed: false },
    api4: { success: false, error: false, completed: false },
  });

  React.useEffect(() => {
    const allCompleted = Object.values(apiStatus).every((status) => status.completed);
    // console.log("hello there", apiStatus, allCompleted);
    if(allCompleted){
      const success = Object.values(apiStatus).every((status) => status.success);
      // console.log("hello there success", success);
      if(success){
        setLoader(false);
      } else {
        enqueueSnackbar("Error fetching data, redirecting to Dashboard", { variant: 'Error' });
        setTimeout(()=>{
          history.push("/ES/dashboard")
        },1000)
      }
    }
  },[apiStatus]);
  

  const setShowClosureHandler = (val) => {
    setShowClosure(val);
    setClosureMessage();
    setEnableRedirect(true);
  };
  const LoadHCPProducts = (affiliateId, hcpId, specialty) => {
    // console.log('Inside Load products',affiliateId, hcpId, specialty)
    let submittedForms = [];
    let sapOrders = [];
    let deliveredForms = [];
    axios
      .get("/api/order/get", {
        headers: {
          URL: `api/hcp/GetPreviousOrder/${affiliateId}/${hcpId}`,
        },
      })
      .then((data)=>{
        // finalForms = data.data.filter(item => item.status !== null);
        submittedForms = data.data.filter(item => item.status == "Submitted");
        sapOrders = data.data.filter(item => item.status == 'Order Sent To SAP');
        deliveredForms = data.data.filter(item => item.status == 'Delivered');
        // console.log(submittedForms, sapOrders, deliveredForms)
        setApiStatus((prevStatus) => ({
          ...prevStatus,
          api1: { success: true, error: false, completed: true },
        }));
      })
      .catch((err) => {
        console.log("----previous order error-------------err------------", err);
        setApiStatus((prevStatus) => ({
          ...prevStatus,
          api1: { success: false, error: true, completed: true },
        }));
      });

    axios
      .get("/api/order/get", {
        headers: {
          URL: "api/hcp/products/" + affiliateId + "/" + hcpId + "/" + specialty,
        },
      })
      .then((data) => {
        // console.log('----products Data------',data)
        let products = Object.values(data?.data?.formProducts?.products);
        let specialtyException = Object.values(
          data?.data?.specialityExceptionList?.specialty
        );
        let specialtyExceptionValue = Object.values(
          data?.data?.specialityExceptionList?.list
        );

        const finalProductArray = specialtyException
          .filter((item1) =>
            specialtyExceptionValue.some(
              (item2) => item2.material_no === item1.material_no
            )
          ) // Filter the matching items
          .map((item1) => {
            const match = specialtyExceptionValue.find(
              (item2) => item2.material_no === item1.material_no
            ); // Find the matching object
            return { ...item1, ...match }; // Merge the matched object properties
          });

        // Example data after merging
        const groupedByProjectGroup = finalProductArray.reduce((acc, item) => {
          const key = item.brand_name;
          // If the group doesn't exist, create it
          if (!acc[key]) {
            acc[key] = {
              brandId: item.product_group_id,
              brandName: item.brand_name,
              Products: []
            };
          }

           // Add the product details to the Products array
          acc[key].Products.push({
            ID: item.product_id,
            Name: item.product_name,
            ColdChain: item.cold_chain,
            Quantities: item.quantities_for_HCPs,
            Quota: item.HCPs_limit,
            packsize: item.pack_size
          });
          return acc;
          }, {});

        const result = Object.keys(groupedByProjectGroup).map(key => groupedByProjectGroup[key]);
        console.log(result);

        SetProductTableData(data.data);
        products = result;
        if (products?.length > 0 && specialtyExceptionValue?.length > 0) {
          // let productsDict ={};
          let productsArray = [];
          let count = 0;
          for (let i = 0; i < products.length; i++) {

            // console.log("this is i", i);
            let product = products[i];
            let brand = new Brand();
            brand.ID = product.brandId;
            brand.Name = product.brandName;
            brand.Products = product.Products;

            // filtering out previous data

            product.Products.forEach((i) => {
              console.log("this is i", i);
              let submitForm = [];
              submittedForms?.forEach((form) => {
                let tempData1 = form?.order_data?.filter((obj1) => obj1.Id === i.ID);
              submitForm.push(tempData1);
              });

              let sap = [];
              sapOrders?.forEach((form) => {
                let tempData1 = form?.order_data?.filter((obj1) => obj1.Id === i.ID);
                sap.push(tempData1);
              });

              let delivered = [];
              deliveredForms.forEach((form) => {
                let tempData3 = form?.order_data?.filter((obj1) => obj1.Id === i.ID);
              delivered.push(tempData3);
              });

            
            let transitValue = submitForm?.reduce(
              (sum, item) => sum + (item?.length > 0 ? item[0]?.Quantity : 0),
              0
            );

            let deliveredValue = delivered?.reduce(
              (sum, item) => sum + (item?.length > 0 ? item[0]?.Quantity : 0),
              0
            );

            i.Quotas = new Quotas();

            i.Quotas.Total = i.Quota
            i.Quotas.Delivered = Number.isInteger(deliveredValue)
              ? deliveredValue
              : 0;
            i.Quotas.inTransit = Number.isInteger(transitValue)
              ? transitValue
              : 0;


            count += 1;

            })

                      
            //filter out forms in date range

            // let finalSubmitForms = submitForm.map(subArray => {
            //   return subArray.filter(submit=> {
            //     const matchingSpecialty = specialty.find(spec => spec.product_id == submit.ID);
            //     if(matchingSpecialty){
            //       return submit
            //     }
            //   })
             
            // })

            // console.log(submitForm);
            // console.log(sap);
            // console.log(delivered);

            //assigning quota
            
            
            // product?.Products.forEach(i => {
            //   brand.Quotas.Total += (finalProductArray.find(item => item?.product_id === i.ID)).HCPs_limit;
            // });

            // brand.Quotas.Delivered = Number.isInteger(deliveredValue)
            //   ? deliveredValue
            //   : 0;
            // brand.Quotas.inTransit = Number.isInteger(transitValue)
            //   ? transitValue
            //   : 0;
            
            // console.log(deliveredValue, transitValue);

            //brand.UniqueCode = product.materialNo+'_'+product.affiliateId;
            // count = count + product.Products.length;
            // console.log("------------products---------------", brand);
            // if (brand?.Quotas?.Total) {
            productsArray.push(brand);
            // }
          }
          // console.log("----> brands",productsArray);
          SetProductCount(count);

          SetproductCountCheck(true);
          // console.log(
          //   "------------products----------SetproductCountCheck-----",
          //   count
          // );
          SetBrandState(
            productsArray.sort((a, b) =>
              a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
            )
          );
          // console.log(
          //   "-------set state----------SetBrandState--------",
          //   productsArray,
          //   typeof productsArray
          // );
          SetbrandCheck(true);
        } else {
          setClosureMessage(
            "Currently there are no products available. Please contact your Sales Representative by clicking the contact button above."
          );
          setEnableCancelRedirect(true);
          setEnableRedirect(false);
          setShowClosure(true);
        }
      setApiStatus((prevStatus) => ({
          ...prevStatus,
          api2: { success: true, error: false, completed: true },
        }));
      })
      .catch((err) => {
        console.log("----previous order error-------------err------------", err);
        setApiStatus((prevStatus) => ({
          ...prevStatus,
          api2: { success: false, error: true, completed: true },
        }));
      });
  };

  const handleStartDateChange = (dateNew) => {
    let date = new Date(dateNew.getTime());
    // console.log("pppponch", date, announcementData.end_date);
    let delivery_date = "";
    if (date == null) {
      delivery_date = "This field is required";
    } else if (
      date.getTime() < new Date().getTime()
    ) {
      delivery_date = "The value of the field must be greater than today's date.";
    } else {
      delivery_date = "";
    }
    setdeliverydate(date)
  };
  
  const LoadAffiliateInfo = (affiliateCode, _sessionData) => {
    console.log('inside LoadAffiliateInfo')
    axios
      .get("/api/admin/get", {
        // api/hcp/products/1/au-969hcp/Administration
        headers: {
          URL: "adminService/User/affiliate/" + affiliateCode,
        },
      })
      .then((data) => {
        // console.log(data);
        let affiliate = data.data;
        // GetHCPDetailsNew('au-969hcp', affiliate.affiliate_name);
        SetAffiliateData(affiliate);
        LoadHCPProducts(affiliate.affiliate_id, _sessionData.CRMID, _sessionData.Specialty);
        // GetAnnouncement(affiliate.affiliate_id);
        setApiStatus((prevStatus) => ({
          ...prevStatus,
          api3: { success: true, error: false, completed: true },
        }));
      })
      .catch((err) => {
        console.log("----affiliate data error-------------err------------", err);
        setApiStatus((prevStatus) => ({
          ...prevStatus,
          api3: { success: false, error: true, completed: true },
        }));
      });
  };
  const GetHCPDetailsNew = (query, affiliateCode) => {
    axios
      .get("/api/order/get", {
        headers: {
          URL: "api/hcpdata/GetHCPEPH/" + affiliateCode + "/" + query + "/200",
        },
      })
      .then((data) => {
        // console.log(data)
        let hcps = data.data;
        if (hcps.length > 0) {
          //let hcpList = [];
          //for (let i = 0; i < hcps.length; i++) {
          // for(let j=0;j< hcps[i].hcp_specialty.length;j++){
          // let model = new HCPModel();
          let hcp = hcps[0];
          // model.ID = hcp.identifiers;
          // model.Name = hcp.firstname + ' '+ hcp.lastname;
          //let specialty = hcp.hcp_specialty[j];
          // model.Speciality = specialty;
          let _addresses = hcp.address; // name to be changed
          let addressArray = [];
          if (_addresses.length > 0) {
            for (let k = 0; k < _addresses.length; k++) {
              let address = _addresses[k];
              let _address = new Address();

              let addressLine = address.addressline.split(",");
              _address.Line1 = addressLine[0].trim();
              _address.Line2 = "";
              if (addressLine.length > 1) {
                for (let index = 1; index < addressLine.length; index++) {
                  const element = addressLine[index];
                  _address.Line2 =
                    _address.Line2 + (index != 1 ? ", " : "") + element;
                }
              }
              _address.Line3 = address.city;
              _address.Line4 = address.state;
              _address.AreaCode = address.zip;
              // let addressLine = address.addressline.split(' ');
              // if (addressLine.length.length > 3) {
              //     for (let l = 0; l < addressLine.length - 3; l++) {
              //         let newAddressSplitted = addressLine[l];
              //         _address.Line1 = _address.Line1 + newAddressSplitted;
              //         //_address.Line2 = address.AddressLine2;
              //     }
              // } else {
              //     _address.Line1 = address.addressline;
              // }
              // _address.AreaCode = address.zip;
              // // _address.Line1 = address.AddressLine1;
              // // _address.Line2 = address.AddressLine2;
              // _address.Line3 =
              //     address.city +
              //     ' ' +
              //     (addressLine.length > 2 ? addressLine[addressLine.length - 2] : address.state);
              //_address.Line4 = address.country;
              addressArray.push(_address);
            }
          }
          SetAddressState(addressArray);
          // console.log(
          //   "-------set state----------SetBrandState--------",
          //   addressArray
          // );
          SetAddressStateCheck(true);
          //}
          // }
          // SetTempHcpList(hcpList);
          //     SetHcpList(hcpList);
        } else {
          // SetTempHcpList([]);
          // SetHcpList([]);
        }
        // setHcpSearchStatusText(
        //     'Search Completed! Unable to find any HCPs. Please update filter condition and try again.'
        // );
      })
      .catch((err) =>
        console.log(
          "----------GetHCPDetailsNew--------err----------------------",
          err
        )
      );
  };

  useEffect(() => {
    const Address = `${addressLine}, ${city}, ${state}, ${zip}`;
    setHcpAddressId(Address);
  }, [addressLine, city, state, zip])
  

  const LoadFormData =  async (id, affiliateCode) =>{
    // console.log('LoadFromData', id, affiliateCode);

    axios
    .get("/api/order/get", {
      headers: { URL: "api/hcp/GetOrder/" + id },
    })
    .then((data) => {
      let orderForm = data.data
      console.log('OrderForm', orderForm);
      setFormId(`SP-IT-${id}`)
      setHcpAddressId(orderForm.hcp_address)
      setHCPAddress(orderForm.hcp_address)
      setAddressLine(orderForm.hcp_address_object?.country)
      setCity(orderForm.hcp_address_object?.city)
      setState(orderForm.hcp_address_object?.state)
      setZip(orderForm.hcp_address_object?.zip)
      setdeliverydate(orderForm.delivery_date)
      setlineno(orderForm.line_number)
      // setnpc(orderForm.national_product_code)
      setadn(orderForm.additional_destination_name)
      setphone(orderForm.phone_number)
      setsecondtitle(orderForm.second_title_text)
      setDeliveryInstruction(orderForm?.delivery_instruction)
      setCreatedOnDate(orderForm?.created_on)
      forwardStates.OrderDetails.set(orderForm.order_data)
      setApiStatus((prevStatus) => ({
        ...prevStatus,
        api4: { success: true, error: false, completed: true },
      }));
    })
    .catch((err) => {
      console.log("----form data error-------------err------------", err);
      setApiStatus((prevStatus) => ({
        ...prevStatus,
        api4: { success: false, error: true, completed: true },
      }));
    });
  }

  const LoadHCPAddress = async (id, affiliateCode) => {

    let modelAPI = new HCPSearchAPIModel();
    modelAPI.searchFilter = "Spain",
    modelAPI.countryCode = "Spain";
    modelAPI.count = 200;

    axios
    .post("/api/order/post", modelAPI, {
      headers: {
        URL: "api/hcpdata/GetHCPEPHPost", // + affiliateCode + '/' + query + '/200',
      },
    })
    .then((data) => {
      let hcps = data.data;
      // setHCPAddress(["abc.com","xyz.com"])
      SetAddressState(hcps[0].address)
      // console.log(hcps[0])
      // setHcpaddressDropDown(() => {
      //   const hcpItems = hcps[0].address.map((address, i) => {
      //     return (
      //       <MenuItem key={i} value={address}>
      //         {address.Line1 +
      //           ', ' +
      //           address.Line2 +
      //           ', ' +
      //           address.Line3 +
      //           '(' +
      //           address.AreaCode +
      //           ')'}
      //       </MenuItem>
      //     )
      //   })
      // })
    }).catch((err) => console.log(err))




    console.log('inside Load HCPadress')
    // axios
    //   .get("/api/order/get", {
    //     // api/hcp/products/1/au-969hcp/Administration
    //     headers: {
    //       URL: "OrderService/HCP/SearchHCP/" + id + "/" + affiliateCode,
    //     },
    //   })
    //   .then((data) => {
    //     console.log(data.data)
    //     let addressDetails = data.data[0].HCO_Data;
    //     if (addressDetails.length > 0) {
    //       // let productsDict ={};
    //       let addressArray = [];
    //       // let count= 0;
    //       for (let i = 0; i < addressDetails.length; i++) {
    //         let address = addressDetails[i];
    //         let _address = new Address();
    //         _address.AreaCode = address.postal_code;
    //         _address.Line1 = address.AddressLine1 + " " + address.AddressLine2;
    //         _address.Line2 = address.AddressLine3 + " " + address.AddressLine4;
    //         _address.Line3 = address.AddressLine5;
    //         // count = count+ product.Products.length;
    //         addressArray.push(_address);
    //         console.log("------------address---------------", _address);
    //       }

    //       console.log("------------address----------SetproductCountCheck-----");
    //       SetAddressState(addressArray);
    //       console.log(
    //         "-------set state----------SetBrandState--------",
    //         addressArray
    //       );
    //       SetAddressStateCheck(true);
    //     }
    //   })
    //   .catch((err) =>
    //     console.log("----LoadHCPAddress-------------err------------", err)
    //   );
  };
  const GetAnnouncement = (affiliateId) => {
    axios
      .get("/api/admin/get", {
        // api/hcp/products/1/au-969hcp/Administration
        headers: {
          URL:
            "api/v1/announcement?status_flag=true&affiliate_id=" + affiliateId,
        },
      })
      .then((data) => {
        let announcements = data.data.data;
        // console.log(announcements);
        let output = [];
        for (let i = 0; i < announcements.length; i++) {
          let announcement = announcements[i];
          let currentDate = new Date();

          let todayDate = `${currentDate.getFullYear()}-${
            (currentDate.getMonth() > 8 ? "" : "0") +
            (currentDate.getMonth() + 1)
          }-${(currentDate.getDate() > 9 ? "" : "0") + currentDate.getDate()}`;
          if (
            todayDate >= announcement.start_date &&
            todayDate <= announcement.end_date
          ) {
            announcement.show = true;
            output.push(announcement);
          }
        }
        // console.log(output);
        SetAnnouncement(output);
        setAnnouncementstatus(true);
      })
      .catch((err) =>
        console.log("-----GetAnnouncement------err---------", err)
      );
  };
  const setUserDetails = () => {
    // console.log("------SalesRepOnline----------setUserDetails--------------");
    let session = GetSessionInformation().then((sessionData) => {
      const _sessionData = JSON.parse(sessionData.payload);
      SetSessionCurrentUser(_sessionData);
      // console.log(
      //   "------setUserDetails----------GetSessionInformation--------------",
      //   _sessionData
      // );
      let stateData = {
        SalesRep: {
          Name: _sessionData._userName,
          Status: "draft",
          ID: _sessionData._lillyId,
          Email: _sessionData._userEmail,
          DSM: "DSM Name",
          FormID: "REQIN12345678901",
          Address: {
            Line1: "123/4",
            Line2: "Some1 Suburb",
            Line3: "Some 5City",
            AreaCode: "Some5 Code",
            Instructions: `some multiline instrucntions
          for example`,
          },
        },
      };
      setCurrentUser(stateData);
      LoadSalesRepProducts(
        _sessionData._currentUser.usermodel.affiliate_id,
        _sessionData._lillyId,
        _sessionData._currentUser.usermodel.user_id
      );
    });
  };
  
  React.useEffect(() => {
    GetSessionInformation().then((data) => {
      // console.log(data);
      const sessionData = JSON.parse(data.payload);
      SethcpSessionData(sessionData._data);
      // console.log(
      //   "-------------sessionData----------------------",
      //   sessionData
      // );
      // console.log("useEffects", id);
      GetSessionInformation().then((data) => {
      const sessionData = JSON.parse(data.payload);
      // console.log(sessionData._data);
      setSessionContext(sessionData);
      SetSessionCurrentUser(sessionData);
      setHCPSpeciallity(sessionData._data.Speciality);
      setHCPID(sessionData._data.HCPID ?? "12345");
      setHCPProfession(sessionData._data.ProfessionalGroup);
      setHcpEmail(sessionData._data.Email);
      setHCPName(sessionData._data.DisplayName);
      LoadAffiliateInfo('SP', sessionData._data);
      // setUserDetails();
      LoadHCPAddress('AU-5494890HCP' ,sessionData._data.CountryCode);
      setTimeout(setDataLoadStatus, 2000, true);
      LoadFormData(id, sessionData._data.CountryCode)

      // setFormId()
    });
      
    });
  }, []);



  // React.useEffect(() => {
  //   console.log('Loading HCP address')
  //     LoadAffiliateInfo(sessionData._data.CountryCode, sessionData._data);
  //     // GetHCPDetailsNew('AU-5494890HCP', sessionData._data.CountryCode);
  //     // LoadHCPAddress('AU-5494890HCP' ,sessionData._data.CountryCode);
  //     // setHCPName(() => {
  //     //   var sentence = sessionData._data.DisplayName.toLowerCase()
  //     //     .trim()
  //     //     .split(" ");
  //     //   for (var i = 0; i < sentence.length; i++) {
  //     //     if (sentence[i])
  //     //       sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  //     //   }
  //     //   return sentence.join(" ");
  //     // });
  // }, []);
  let history = useHistory();

  // const forwardStates = {
  //   HCP: {
  //     Id: { get: hcpID, set: setHCPID },
  //     Name: { get: hcpName, set: setHCPName },
  //     Address: { get: hcpAddress, set: setHcpAddress },
  //   },
  //   OrderDetails: {
  //     get: orderDetails,
  //     set: setOrderDetails,
  //   },
  //   consent: {
  //     get: consentCheckBox,
  //     set: setconsentCheckBox,
  //   },
  //   atlantisConsent: {
  //     get: atlantisCheckBox,
  //     set: setAtlantisCheckBox,
  //   },
  //   Attachments: {
  //     get: attachments,
  //     set: setAttachments,
  //   },
  // };
  const forwardStates = {
    session: {
      get: sessionCurrentUser,
    },
    OrderDetails: {
      get: orderDetails,
      set: setOrderDetails,
  },
    hcpModifiedProducts: {
      get: brandState,
      set: SetBrandState,
    },
    Attachments: {
      get: attachments,
      set: setAttachments,
    },
    HCP: {
      Name: {
        get: hcpName,
        set: setHCPName,
      },
      ID: {
        get: hcpID,
        set: setHCPID,
      },
      Email: {
        get: hcpEmail,
        set: setHcpEmail,
      },
      delivery_date:{
        get: deliverydate,
        set: setdeliverydate
      },
      additional_destination_name:{
        get: adn,
        set: setadn
      },
      // national_product_code:{
      //   get: npc,
      //   set: setnpc
      // },
      line_number:{
        get: lineno,
        set: setlineno
      },
      second_title_text:{
        get: secondtitle,
        set: setsecondtitle
      },
      phone_number:{
        get: phone,
        set: setphone
      },
      Address: {
        get: HCPAddress,
        set: setHCPAddress,
      },
      DropDownAddress: {
        get: hcpaddressDropDown,
        set: setHcpaddressDropDown,
      },
      Speciallity: {
        get: hcpSpeciallity,
        set: setHCPSpeciallity,
      },
      Profession: {
        get: hcpProfession,
        set: setHCPProfession,
      },
      Consents: {
        Privacy: {
          get: privacyConsent,
          set: setPrivacyConsent,
        },
        Marketing: {
          get: marketingConsent,
          set: setMarketingConsent,
        },
      },
    },
    Order: {
      get: orderDetails,
      set: setOrderDetails,
    },
  };
  const classes = useStyles();

  const fetchFormData = (formType) => {
    return;
  };
  const GetProductIds = () => {
    let brandIdArray = [];
    let orderArray = forwardStates.OrderDetails.get;
    for (let i = 0; i < orderArray.length; i++) {
      let order = orderArray[i];
      brandIdArray.push(order.Id);
      // console.log("----------GetProductIds------------------------", order.Id);
    }
    return brandIdArray;
  };
  const submitData = () => {
    // console.log(
    //   "------------------forwardStates-------------------",
    //   forwardStates, sessionContext, affiliateData, hcpSessionData
    // );
    let model = new SubmitModel();
    model.status = "Submitted";
    model.affiliate_id = affiliateData.affiliate_id;
    model.attachments = forwardStates.Attachments.get;
    model.created_by_user_id = hcpSessionData.CRMID;
    model.delivery_instruction = deliveryInstruction
    model.order_data = forwardStates.OrderDetails.get; //calc
    model.hcp_address_object = {
      "zip": addressLine,
      "city": city,
      "state": state,
      "country": zip,
      "addressline": `${addressLine}, ${city} ,${state}, ${zip}`
    };
    model.hcp_address = addressLine?.length > 0 ? `${addressLine}, ${city} ,${state}, ${zip}` : ""
    model.hcp_id = hcpSessionData.CRMID;
    model.hcp_name = forwardStates.HCP.Name.get;
    model.last_name1 = forwardStates.HCP.Name.get?.split(' ')[1] || '';
    model.last_name2 = forwardStates.HCP.Name.get?.split(' ')[2] || '';
    /*****************/

    model.delivery_date= forwardStates.HCP.delivery_date.get;
    model.additional_destination_name= forwardStates.HCP.additional_destination_name.get;
    // model.national_product_code= forwardStates.HCP.national_product_code.get;
    model.second_title_text= forwardStates.HCP.second_title_text.get;
    model.phone_number= forwardStates.HCP.phone_number.get?.length <= 0 ? null : forwardStates.HCP.phone_number.get;
    model.line_number= forwardStates.HCP.line_number.get;

    /****************/
    let productArray = Object.values(productTableData.product);
    // console.log(
    //   "-------Object.values(productTableData.product);---------------------------",
    //   productArray, model
    // );
    let brandIdArray = GetProductIds();
    for (let i = 0; i < brandIdArray.length; i++) {
      let product = productArray.filter((v) => v.product_id == brandIdArray[i]);
      if (product != undefined && product != null) {
        // console.log(
        //   "---if(product!=undefined && product!=null)--------------------------------",
        //   product
        // );
        let nameId = product[0].material_no + "_" + product[0].affiliate_id;
        model.product_array[nameId] = productTableData.product[nameId];
      }
    }
    //model.product_array = productTableData;//calc
    // model.created_on= forwardStates.HCP.Id;
    model.updated_by_user_id = hcpSessionData.CRMID;
    model.email = hcpSessionData.Email;
    model.Specialty = hcpSessionData.Specialty;
    // model.updated_on = forwardStates.Attachments;
    // console.log("----------------------------------save model ", model);
    // return;
    axios
      .post("/api/order/post", model, {
        headers: {
          URL:
            "api/hcp/save/" +
            affiliateData.affiliate_name +
            "/" +
            affiliateData.Affiliate_code,
        },
      })
      .then((res) => {
        // console.log("----------------User Save--------------", res.data);
        setTimeout(()=>{
          history.push("/ES/dashboard")
        },1000)
        return res;
      })
      .catch((err) => {
        console.log("save Failed", err);
        return err;
      });
  };

  const submitDraftData = (type) => {
    // console.log(
    //   "------------------forwardStates-------------------",
    //   forwardStates, sessionContext, affiliateData, hcpSessionData, from_server
    // );
    let model = new SubmitModel();
    model.order_id = orderID;
    model.status = type;
    model.affiliate_id = affiliateData.affiliate_id;
    model.attachments = forwardStates.Attachments.get;
    model.created_by_user_id = hcpSessionData.CRMID;
    model.delivery_instruction = deliveryInstruction
    model.order_data = type === "Submitted" ? forwardStates.OrderDetails.get : []; //calc
    model.hcp_address_object = {
      "zip": zip,
      "city": city,
      "state": state,
      "country": addressLine,
      "addressline": `${addressLine}, ${city} ,${state}, ${zip}`
    };
    model.hcp_address = addressLine?.length > 0 ? `${addressLine}, ${city} ,${state}, ${zip}` : ""
    model.hcp_id = hcpSessionData.CRMID;
    model.hcp_name = forwardStates.HCP.Name.get;
    model.last_name1 = forwardStates.HCP.Name.get?.split(' ')[1] || '';
    model.last_name2 = forwardStates.HCP.Name.get?.split(' ')[2] || '';
    /*****************/

    model.delivery_date= forwardStates.HCP.delivery_date.get;
    model.additional_destination_name= forwardStates.HCP.additional_destination_name.get;
    // model.national_product_code= forwardStates.HCP.national_product_code.get;
    model.second_title_text= forwardStates.HCP.second_title_text.get;
    model.phone_number= forwardStates.HCP.phone_number.get?.length <= 0 ? null : forwardStates.HCP.phone_number.get;
    model.line_number= forwardStates.HCP.line_number.get;

    /****************/
    model.created_on = createdOnDate;
    let productArray = Object.values(productTableData.product);
    // console.log(
    //   "-------Object.values(productTableData.product);---------------------------",
    //   productArray, model
    // );
    let brandIdArray = GetProductIds();
    for (let i = 0; i < brandIdArray.length; i++) {
      let product = productArray.filter((v) => v.product_id == brandIdArray[i]);
      if (product != undefined && product != null) {
        // console.log(
        //   "---if(product!=undefined && product!=null)--------------------------------",
        //   product
        // );
        let nameId = product[0].material_no + "_" + product[0].affiliate_id;
        model.product_array[nameId] = productTableData.product[nameId];
      }
    }
    //model.product_array = productTableData;//calc
    // model.created_on= forwardStates.HCP.Id;
    model.updated_by_user_id = hcpSessionData.CRMID;
    model.email = hcpSessionData.Email;
    model.Specialty = hcpSessionData.Specialty;
    // model.updated_on = forwardStates.Attachments;
    // console.log("----------------------------------save model ", model);
    // return;
    axios
      .post("/api/order/post", model, {
        headers: {
          URL:

          "api/hcp/update/" +
          affiliateData.affiliate_name +
          "/" +
          affiliateData.Affiliate_code +
          "/" +
          formId?.split("-")[2],
        },
      })
      .then((res) => {
        // console.log("----------------User Updated--------------", res.data);
        setTimeout(()=>{
          history.push("/ES/dashboard")
        },1000)
        return res;
      })
      .catch((err) => {
        console.log("update Failed", err);
        return err;
      });
  };

  const handleClick = (type) => {
    switch (type) {
      case "submit":
        // console.log("Submit", from_server.HCP.Address[HCPAddress])
        let validated = true;
        if (forwardStates.OrderDetails.get.length == 0) {
          setProductError(true);
          validated = false;
        } else {
          setProductError(false);
        }
        // console.log(forwardStates.HCP.Address.get);
        if (HCPAddress == undefined) {
          setSelectAddressError(true);
          validated = false;
        } else {
          setSelectAddressError(false);
        }

        if (validated) {
          setTextSnackBar("Form submitted successfully!");
          setTypeSnackBar("success");
          !isEdit ? submitData() : submitDraftData('Submitted');
        } else {
          setTextSnackBar("Please fix highlighted errors!");
          setTypeSnackBar("error");
        }
        setOpenSnackBar(true);

        break;
      case "error":
        setTextSnackBar("Form deleted!");
        setTypeSnackBar("error");
        setOpenSnackBar(true);
        break;
      case "cancel":
        setShowClosure(true);
        // setTextSnackBar('Form deleted!');
        // setTypeSnackBar('warning');
        break;
      case "save":
        // console.log("Submit", from_server.HCP.Address[HCPAddress])
        validated = true;
        setProductError(false);
        setSelectAddressError(false);
        submitDraftData('Draft');

        setTextSnackBar("Form Saved in drafts!");
        setTypeSnackBar("info");
        setOpenSnackBar(true);
        setTimeout(()=>{
          history.push("/ES/dashboard")
        },1000)
        break;
    }
  };

  
  const handelAnnouncementClose = (i) => {
    // console.log(i);
    SetAnnouncement((pval) => {
      // console.log(pval);

      let tempVal = pval;
      tempVal[i].show = false;
      return tempVal;
    });
    setforceUpdate((p) => p + 1);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
    if (typeSnackBar == "success") history.replace("./");
  };

  

  const generateHCPAddressMenuList = (props) => {
    // console.log('generateHCPAddressMenuList', props);
    return props.map((address, i) => {
      return (
        <MenuItem key={i} value={i}>
          {address.addressline +
            ', ' +
            address.country +
            ', ' +
            address.state +
            '(' +
            address.zip +
            ')'}
        </MenuItem>
      );
    });
  };

  const onHCPAddressChange = (value) =>{
    // console.log('onHCPAddressChange', addressState[value])
    let address = addressState[value]
    setAddressLine(address?.addressline)
    setCity(address?.city)
    setState(address?.state)
    setZip(address?.zip)
  }


  let from_server = {
    HCP: {
      Name: hcpSessionData,
      ID: hcpSessionData,
      Speciality: hcpSessionData,
      Address: addressState,
    },
    Products: {
      TotalCount: productCount,
      Brands: brandState,
    },
    SalesRep: {
      FormID: formId,
      Name:
        sessionContext?._data !== undefined
          ? sessionContext._data.DisplayName
          : "Jivesh",
      ID:
        sessionContext?._data !== undefined
          ? sessionContext._data.DisplayName
          : "L012062",
      Email:
        sessionContext?._data !== undefined
          ? sessionContext._data.Email
          : "Jivesh",
      Address: {
        Line1: addressLine  || "123/4",
        Line2: city || "Some1 Suburb",
        Line3: state || "Some 5City",
        AreaCode: zip || "Some5 Code",
      },
    },
  };

  let stateCheck = !(
    addressStateCheck &&
    productCountCheck &&
    brandCheck &&
    announcmentStatus &&
    brandState.length > 0
  );

  return (
    <>
      { !loader ? (
        <div className={styles.container}>
          {/* {stateCheck ? (
          <Skeleton.textField bgColor="#fff4e5" />
        ) : (
          announcement.map((annon, i) => {
            console.log(annon);
            return (
              <Collapse in={annon.show}>
                <Alert
                  color="warning"
                  severity="info"
                  onClose={() => {
                    handelAnnouncementClose(i);
                  }}
                >
                  <AlertTitle>{annon.announcement_title}</AlertTitle>
                  {annon.announcement_text}
                </Alert>
              </Collapse>
            );
          })
        )} */}
          <div className={styles.containerPaper}>
            <h3 className={styles.headerBottomPadding}>Sample Request Form</h3>
          </div>

          <FormTemplate
            baseDetails={from_server.SalesRep}
            attachments={forwardStates.Attachments}
            toShow={false}
            // toSave={handelSubmit}
            hideSave={true}
            // submitFunction={handelSubmit}
          >
            <Grid container>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="hcpName"
                  label="HCP Name"
                  value={hcpSessionData.DisplayName}
                  setValue={setHCPName}
                  isRequired={true}
                  isDisabled={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="hcpId"
                  label="HCP ID"
                  value={hcpSessionData.CRMID ?? "12345HCP"}
                  setValue={setHCPID}
                  isDisabled={true}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="profession"
                  label="Profession"
                  value={hcpSessionData.ProfessionalGroup}
                  setValue={setHCPProfession}
                  isDisabled={true}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="speciallity"
                  label="Speciallity"
                  value={hcpSessionData.Specialty}
                  setValue={setHCPSpeciallity}
                  isDisabled={true}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputBlock
                  id="hcpAddress"
                  label="HCP Address"
                  value={hcpAddressId}
                  // setValue={s}
                  isDisabled={true}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="addressline"
                  label="Address Line"
                  value={addressLine}
                  setValue={setAddressLine}
                  isDisabled={isEdit ? false : true}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="city"
                  label="City"
                  value={city}
                  setValue={setCity}
                  isDisabled={isEdit ? false : true}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="state"
                  label="State"
                  value={state}
                  setValue={setState}
                  isDisabled={isEdit ? false : true}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="zip"
                  label="ZIP"
                  value={zip}
                  setValue={setZip}
                  isDisabled={isEdit ? false : true}
                  isRequired={true}
                />
              </Grid>
              {isSpainCheck === "ES" && <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="phone_number"
                  label="Phone Number"
                  value={phone}
                  setValue={setphone}
                  isDisabled={isEdit ? false : true}
                  isRequired={false}
                  type={"number"}
                />
              </Grid>}
              {/* {isSpainCheck === "ES" && <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="npc"
                  label="National Product Code"
                  value={npc}
                  setValue={setnpc}
                  isDisabled={isEdit ? false : true}
                  isRequired={false}
                />
              </Grid>} */}
              {isSpainCheck === "ES" && <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="adn"
                  label="Additional Destination Name"
                  value={adn}
                  setValue={setadn}
                  isDisabled={isEdit ? false : true}
                  isRequired={false}
                />
              </Grid>}
              {isSpainCheck === "ES" && <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="line_number"
                  label="Line Number"
                  value={lineno}
                  setValue={setlineno}
                  isDisabled={isEdit ? false : true}
                  isRequired={false}
                />
              </Grid>}
              {isSpainCheck === "ES" && 
              <>
              <Grid item xs={12} md={6}>
                <FormInputBlock
                  id="second_title_text"
                  label="Second Title Text"
                  value={secondtitle}
                  setValue={setsecondtitle}
                  isDisabled={isEdit ? false : true}
                  isRequired={false}
                />
              </Grid>

              <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      size="medium"
                      className={classes.DatePickerColor}
                    > 
                      <Grid item xs={6} sm={2} style={{marginLeft : "20px", maxWidth: "22.5%", flexBasis: "22.5%"}}>
                      <Typography
                          variant="subtitle1"
                          className={classes.addDepotSubTitle}
                          style={{opacity: isEdit ? "100%" : "40%"}}
                        >
                          Delivery Date
                      </Typography>
                      </Grid>
                      <Grid item xs={6} sm={2} style={{ maxWidth: "24.666667%", flexBasis: "24.666667%"}}>
                      <KeyboardDatePicker
                        onClick={() => setStartDateOpen(true)}
                        open={startDateOpen}
                        onClose={() => setStartDateOpen(false)} 
                        TextFieldComponent={(props) => (
                          <TextField {...props}  />
                        )}
                        inputVariant="outlined"
                        // disablePast
                        minDate={minDate}
                        fullWidth
                        format={"dd/MM/yyyy"}
                        size="small"
                        name="delivery_date"
                        value={deliverydate}
                        onChange={handleStartDateChange}
                        autoOk
                        clearable
                        inputProps={{
                          readOnly: true,
                        }}
                        disabled={isEdit ? false : true}
                      />
                      </Grid>
              </MuiPickersUtilsProvider>
              </>
              }
              {/* <Grid item xs={12}>
                <FormSelectBlock
                  label="HCP Address"
                  MenuItems={generateHCPAddressMenuList(addressState)}
                  id="HCPaddress"
                  value={HCPAddress}
                  setValue={setHCPAddress}
                  error={selectAddressError}
                  helperText={
                    selectAddressError ? "Please select Delivery address!" : ""
                  }
                />
              </Grid> */}
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <FormInputBlock
                  id="instruction"
                  label="Delivery Instructions"
                  value={deliveryInstruction}
                  setValue={setDeliveryInstruction}
                  isDisabled={isEdit ? false : true}
                />
              </Grid>
            </Grid>
          </FormTemplate>
          {/* {console.log("===> products", from_server.Products)} */}
          {isEdit ? 
          <ProductDetails
            orderDetails={forwardStates.OrderDetails}
            Products={from_server.Products}
            stateCheck={stateCheck}
            error={productError}
            additionalCheckbox={forwardStates.atlantisConsent}
            diplayType="HCP"
          /> : 
            <OrderDetails
            TotalCount={forwardStates?.OrderDetails?.get?.length}
            Orders={forwardStates?.OrderDetails?.get}
            />
          }

          {/* <Consents error={checkBoxError} stateCheck={stateCheck} states={forwardStates.consent} /> */}
          <OnClosureModal
            showClosure={showClosure}
            setShowClosure={setShowClosureHandler}
            message={closureMessage}
            enableRedirect={enableRedirect}
            redirectTo="dashboard"
            // redirectTo="HCP"
          />
          
          <Grid container justifyContent="center" alignContent="center">
            <Button
              size="large"
              className={styles.ButtonAlignCenter}
              variant="contained"
              onClick={() => handleClick("cancel")}
              // disabled={!enableCancelRedirect && (openSnackBar || stateCheck)}
            >
              Go back
            </Button>
            { isEdit && <Button
              size="large"
              variant="contained"
              onClick={() => handleClick("save")}
              className={`${styles.ButtonAlignCenter} ${styles.ColorbackgroundPrimary}`}
              // disabled={openSnackBar || stateCheck}
              disabled={!enableCancelRedirect && (openSnackBar || stateCheck) && !isEdit}
              style={{cursor: !isEdit ? "not-allowed" : "pointer"}}
            >
              Save
            </Button>}
            { isEdit && <Button
              size="large"
              variant="contained"
              onClick={() => handleClick("submit")}
              className={`${styles.ButtonAlignCenter} ${styles.ColorbackgroundPrimary}`}
              // disabled={openSnackBar || stateCheck}
              disabled={!enableCancelRedirect && (openSnackBar || stateCheck) && !isEdit}
              style={{cursor: !isEdit ? "not-allowed" : "pointer"}}
            >
              Submit
            </Button> }
          </Grid>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={2500}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleClose}
              severity={typeSnackBar}
            >
              {textSnackBar}
            </MuiAlert>
          </Snackbar>
        </div>
      ): <CustomFullLoader />}
    </>
  );
};

export default spainView;
