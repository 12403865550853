import React from 'react';
import { Route, Switch, useHistory, useLocation, Redirect } from 'react-router-dom';
import Template from './components/common/components/template';
import FormRouter from './components/form/router';
import WithConsent from './components/form/withConsent/pages/confirm';
import hcpSpain from './components/form/withConsent/pages/spainForm'

// import our react pages
import SelectAffiliateHCP from './components/pages/SelectAffiliateHCP';
import AuthoriseHCP from './components/pages/AuthoriseHCP';
import './worker';
import { GetSessionInformation, SetSessionInformationHCP } from './Store/Slices/SessionSlice';

// import './worker';

const MainRouter = (props) => {
    let location = useLocation();
    let history = useHistory();

    const fromServer = {
        Affilaites: [
            { ID: 1, Code: 'IN', Name: 'India' },
            { ID: 2, Code: 'AU', Name: 'Australia' },
            { ID: 3, Code: 'NZ', Name: 'New Zealand' },
        ],
    };
    const [auth, setAuth] = React.useState(-1);
    //-1 == not initiated
    //0 == Unauthorized
    //1 == Authorized
    //2 == Single Page Auth.
    const [affiliateCode, setAffiliateCode] = React.useState('NaN');
    const [newAffiliateCode, setNewAffiliateCode] = React.useState('NaN');

    const onUpdate = () => {
        // getRedirectURI();
        return window.scrollTo(0, 0);
    };
    React.useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;
        setNewAffiliateCode(browserLanguage);
        checkAuth();
        // getRedirectURI();
    }, []);

    React.useEffect(() => {
        affiliateCode == 'AU' || affiliateCode == 'NZ' || affiliateCode == 'ES' ? setAuth(1) : '';
    }, [affiliateCode]);

    const checkAuth = async () => {
        {
            // console.log(history.location.pathname.split('/'));
            await GetSessionInformation()
                .then((data) => {
                    const sessionData = JSON.parse(data.payload);
                    let affiliate = null;
                    // console.log(data.payload);
                    if (sessionData != null && sessionData._data != null && sessionData._data.CountryCode != null) {
                        affiliate = sessionData._data.CountryCode;
                        affiliateCode == affiliate ? '' : setAffiliateCode(affiliate);
                        auth == 1 ? '' : setAuth(1);
                    } else {
                        auth == 0 ? '' : setAuth(0);
                    }
                })
                .catch((error) => {
                    auth == 0 ? '' : setAuth(0);
                });
        }
    };

    // console.log('Affilaite Code: ', affiliateCode);
    // console.log('Auth: ', auth);
    return (
        <div>
            {auth == -1 ? (
                ''
            ) : (
                <Switch>
                    <Route
                        exact
                        path={`/SelectAffiliate`}
                        render={(props) => <SelectAffiliateHCP {...props} setAffiliateCode={setAffiliateCode} />}
                    />
                    <Route
                        exact
                        path={`/es-ES/SelectAffiliate`} 
                        render={(props) => <SelectAffiliateHCP {...props} setAffiliateCode={setAffiliateCode} />}
                    />
                    <Route
                        path={`/AuthoriseHCP`}
                        render={(props) => <AuthoriseHCP {...props} setAffiliateCode={setAffiliateCode} />}
                    />
                    {/* {getRedirectURI()} */}
                    {auth == 1 ? (
                        <Template setAffiliateCode={setAffiliateCode}>
                            <FormRouter path={`/${affiliateCode}`} />
                            {/* <Route path="/">
                                <Redirect to={`/${affiliateCode}/Home`} />
                            </Route> */}
                        </Template>
                    ) : (
                        ''
                    )}
                    {auth == 0 ? (
                        <>
                            <Route exact path={`/Confirmation/:id`} component={WithConsent} />

                            <Route
                                exact
                                path="/AU"
                                component={() => {
                                    window.location.replace(
                                        `https://account.lilly.com/en-AU/signin?token_required&title=Start%20Right&url=https://lillystarterpacks.com/AuthoriseHCP`
                                    );
                                    return;
                                }}></Route>
                            <Route
                                exact
                                path="/NZ"
                                component={() => {
                                    window.location.replace(
                                        `https://account.lilly.com/en-NZ/signin?token_required&title=Start%20Right&url=https://lillystarterpacks.com/AuthoriseHCP`
                                    );
                                    return;
                                }}></Route>
                            <Route
                                exact
                                path="/SP"
                                component={() => {
                                    window.location.replace(
                                        `https://account.lilly.com/es-ES/signin/?sso=redirect&title=Start%20Right&url=https://lillystarterpacks.aws.lilly.com/AuthoriseHCP`
                                    );
                                    return;
                                }}></Route>
                            {affiliateCode == 'AU' ||
                            affiliateCode == 'NZ' || affiliateCode == 'SP' ||
                            history.location.pathname.toUpperCase().indexOf('Confirmation'.toUpperCase()) >= 0 ? (
                                ''
                            ) : (
                                <>
                                    { newAffiliateCode == 'es-ES' ? <Redirect to="/es-ES/SelectAffiliate" /> : <Redirect to="/SelectAffiliate" />}
                                </>
                            )}
                        </>
                    ) : (
                        ''
                    )}
                    {/* <Redirect from="/AU" to={} />
          <Redirect
            from="/NZ"
            to={`https://buit-eucan-lilly-passport-dev.herokuapp.com/conen-NZ?sso=login_required&title=Start%20Right&url=http://localhost:60008/AuthoriseHCP`}
          /> */}
                </Switch>
            )}
        </div>
    );
};

export default MainRouter;
